<template>
  <!-- banner start -->
    <div class="banner-area banner-area-2">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5 order-lg-last align-self-center">
                    <div class="thumb text-lg-end">
                        <img src="../../assets/Logo2.png" alt="img">
                    </div>
                </div>
                <div class="col-lg-7 order-lg-first">
                    <div class="banner-inner banner-inner-2">
                        <h3 class="sub-title b-animate-1">اهلا بكم في منصة Buzz</h3>
                        <h6 class="b-animate-2 title">اسلاميات، المطبخ، اطفال، العاب</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- service start -->
    <div class="service-area mg-top--82">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-intro-inner style-2 text-center">
                        <div class="thumb style-bg">
                           <svgIslamyatt />
                        </div>
                        <div class="details">
                            <h4><router-link to="/Islamyat" >أسلاميات</router-link></h4>
                            <router-link class="read-more-text mt-3" to="/Islamyat">قسم الاسلاميات</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-intro-inner style-2 single-intro-inner-active text-center">
                        <div class="thumb style-bg">
                            <svgKitchen />
                        </div>
                        <div class="details media-body">
                            <h4><router-link to="/Kitchen">المطبخ</router-link></h4>
                            <router-link class="read-more-text mt-3" to="/Kitchen">قسم المطبخ</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-intro-inner style-2 text-center">
                        <div class="thumb style-bg">
                           <svgGames />
                        </div>
                        <div class="details">
                            <h4><router-link to="/Game">الالعاب</router-link></h4>
                            <!-- <p>We develop the relationships that under the next phase in your.</p> -->
                            <router-link class="read-more-text mt-3" to="/Game">قسم الالعاب</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- service end -->
    <div class="blog-area pd-top-100">
       <div class="container " >
            <div class="row">
                <div class="col-lg-12">
                    
                    <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                        <div class="section-title style-small ">
                            <h3>اسلاميات</h3>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-3 " v-for="publish in Islamy.slice(0,8)" :key="publish.id">
                            <div class="card card_all" >
                                <h3 class="card__title pointer">{{ publish.name }}</h3>
                                <a @click="getFeed(publish)" class="card__cover pointer">
                                    <img v-lazy="publish.cover" alt="">
                                    <svgVideos />
                                </a>                  
                                <ul class="card__list pointer" >
                                   <li>{{ publish.name }}</li>
                                   <li>{{ publish.createDate.substring(0,10) }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-area pd-top-100">
       <div class="container " >
            <div class="row">
                <div class="col-lg-12">
                    
                    <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                        <div class="section-title style-small arabicKufi">
                           <h3>اطفال</h3>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-3 arabicKufi" v-for="publish in kids.slice(0,8)" :key="publish.id">
                            <div class="card card_all" >
                                <h3 class="card__title pointer">{{ publish.name }}</h3>
                                <a @click="getFeed(publish)" class="card__cover pointer">
                                    <img v-lazy="publish.cover" alt="">
                                    <svgVideos />
                                </a>                  
                                <ul class="card__list pointer">
                                   <li>{{ publish.name }}</li>
                                   <li>{{ publish.createDate.substring(0,10) }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-area pd-top-100">
       <div class="container " >
            <div class="row">
                <div class="col-lg-12">
                    <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                        <div class="section-title style-small arabicKufi">
                            <h3>مطبخ</h3>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-3 arabicKufi" v-for="publish in Kitchen.slice(0,8)" :key="publish.id">
                            <div class="card card_all" >
                                <h3 class="card__title pointer">{{ publish.name }}</h3>
                                <a @click="getFeed(publish)" class="card__cover pointer">
                                    <img v-lazy="publish.cover" alt="">
                                    <svgVideos />
                                </a>                  
                                <ul class="card__list pointer">
                                   <li>{{ publish.name }}</li>
                                   <li>{{ publish.createDate.substring(0,10) }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-area pd-top-100">
       <div class="container " >
            <div class="row">
                <div class="col-lg-12">
                    
                    <div class="row row--grid" style="margin-right: 5px;margin-left: 5px;">
                        <div class="section-title style-small arabicKufi">
                        <h3>العاب</h3>
                    </div>
                        <div class="col-6 col-sm-4 col-lg-3 col-xl-3 arabicKufi" v-for="publish in lastGames.slice(0,8)" :key="publish.id">
                            <div class="card card_all" >
                                <h3 class="card__title pointer">{{ publish.name }}</h3>
                                <a @click="getGame(publish)" class="card__cover pointer">
                                    <img v-lazy="publish.images" alt="">
                                    <svgPlay />
                                </a>                  
                                <ul class="card__list pointer">
                                   <li>العاب</li>
                                   <li>{{ publish.insert_date.substring(0,10) }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { ref, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import svgVideos from "@/components/svgData/svgVideos.vue";
import svgPlay from "@/components/svgData/svgPlay.vue";
import svgIslamyatt from "@/components/svgData/svgIslamyatt.vue";
import svgKitchen from "@/components/svgData/svgKitchen.vue";
import svgGames from "@/components/svgData/svgGames.vue";
export default {
    components: { 
        svgVideos,
        svgPlay,
        svgIslamyatt,
        svgKitchen,
        svgGames
    },
    async setup() {
        const toast = useToast();
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const lastGames = ref([]);
        const Islamy = ref([]);
        const kids = ref([]);
        const Kitchen = ref([]);
        watchEffect(() => {
        if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
          let urlParams = new URLSearchParams(window.location.search);
          if(urlParams.has('msisdn')) {
            HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                if (res.data.status == 1 ) {
                    cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                    toast.success("مرحبا بك معنا  ");
                    if(cookie.getCookie("content_id")!=null){
                      toast.info("سوف يتم تحويلك الي المحتوي",);
                      setTimeout(() =>  router.push({name: "Contents", params: { id: cookie.getCookie("content_id") } }), 2000);
                    } else {
                      setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                } else if (res.data.status == 0 ) {
                  cookie.removeCookie('msisdn');
                    toast.error("لست مشترك في هذة الخدمة",);
                    setTimeout(() => router.push({ path: "/" }), 2500);
                }
                
                })
            }
        }
      });
        try {
            await HTTP.get("getPost.php?LIMIT=12&OFFSET=0").then((res) => {
                lastGames.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("BuzzContent.php?catId=1&LIMIT=12").then((res) => {
                Islamy.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("BuzzContent.php?catId=2&LIMIT=12").then((res) => {
                kids.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("BuzzContent.php?catId=3&LIMIT=12").then((res) => {
                Kitchen.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        
        const getFeed = (publish) => {
            cookie.setCookie('vid', publish.id)
            cookie.setCookie('cat_id', publish.catogeryId)
                router.push({ name: "Content", params: { id: publish.id } });
        };
        const getGame = (publish) => {
            cookie.setCookie('Games_Id', publish.id)
            router.push({ name: "GameDetail", params: { id: publish.id } });
        };
       
        return {
            lastGames,
            Islamy,
            kids,
            Kitchen,
            getFeed,
            getGame
        }
    },
}
</script>

<style scoped>
/* .arabicKufi {
  font-family: 'Droid Arabic Kufi', serif;
} */
.pointer {
  cursor: pointer;
}
.pd-top-100 {
  padding-top: 50px;
}
.card {
    display: flex;
flex-direction: column;
justify-content: flex-start;
/* align-items: flex-start; */
margin-top: 0px;
position: relative;
overflow: hidden;
/* background-image: linear-gradient(0deg,#047072,#00c9c9); */
border-radius: 10px;
text-align: center;
/* box-shadow: 0 15px 20px rgba(0,0,0,.3),0 15px 20px rgba(0,0,0,.22); */
border: 0;
}
.col-xl-3 {
  padding-top: 20px;
}
.card_all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(0deg, #481582 0, #70499D) !important;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 15px 20px rgba(0,0,0,.3),0 15px 20px rgba(0,0,0,.22);
}
.card__title {
  /* font-family: 'Zain_ExtraBold', sans-serif; */
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 3px;
  transition: 0.5s;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  word-wrap: break-word;
  text-shadow: 1px 1px 2px #00000082;
}
.card__cover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 0px;
}
.card:hover .card__cover::before {
  opacity: 0.7;
}
.card__cover::before {

    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.8) 75%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0.1;
    transition: 0.5s;

}
.card__cover img {
  width: 100%;
  position: relative;
  z-index: 1;
  transition: 0.5s;
  height: 100%;
}

.card__cover svg {
  width: 56px;
}
.card__cover svg {
  position: absolute;
  display: block;
  stroke: #fff;
  width: 46px;
  height: auto;
  transition: 0.5s;
  z-index: 3;
  transform: scale(0.8);
  opacity: 0;
}
.card:hover .card__cover svg {
  opacity: 1;
  transform: scale(1);
}
.card__list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 3px;
}
.card__list li {
  font-size: 12px;
  color: #e0e0e0;
  margin-left: 15px;
  white-space: nowrap;
  /* font-family: 'Zain_ExtraBold', sans-serif; */
}
.blog-area ul {
  list-style: none;
  justify-content: center;
  padding-right: 2%;
  padding-left: 2%;
}
.section-title h3::after {
  content: "";
  position: absolute;
  right: 1%;
  bottom: 0;
  height: 2px;
  width: 70px;
  background: #481582;
}

.section-title {
    margin-bottom: 0px;
}
</style>