<template>
  <svg
    version="1.1"
    id="Capa_1"
    fill="#ee27bc"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 54.999 54.999"
    style="enable-background: new 0 0 54.999 54.999"
    xml:space="preserve"
  >
    <g>
      <path
        d="M54.499,39.999h-6V18.458c0-4.932-2.036-9.769-5.588-13.271c-3.454-3.407-7.938-5.237-12.655-5.186
		                                c-9.791,0.132-17.757,8.205-17.757,17.998V22.3c-2.719-1.97-6.417-2.031-9.054-0.011c-1.72,1.317-2.787,3.311-2.93,5.468
		                                c-0.143,2.164,0.648,4.279,2.178,5.809c0.054,0.053,5.396,5.306,8.294,8.04c0.961,0.908,1.512,2.19,1.512,3.518v1.876v8h36v-8v-1h6
		                                V39.999z M46.499,52.999h-32v-4h32V52.999z M46.499,46.999h-32v-1.876c0-1.875-0.779-3.688-2.139-4.972
		                                c-2.883-2.721-8.211-7.959-8.259-8.006c-1.115-1.116-1.695-2.668-1.59-4.257c0.105-1.605,0.869-3.03,2.15-4.012
		                                c2.172-1.664,5.349-1.378,7.391,0.663c0.146,0.146,0.322,0.241,0.506,0.313l3.494,3.168c0.191,0.173,0.432,0.259,0.672,0.259
		                                c0.272,0,0.543-0.11,0.741-0.328c0.37-0.409,0.34-1.042-0.069-1.413l-2.948-2.673c0.027-0.11,0.05-0.222,0.05-0.339v-5.527
		                                c0-8.705,7.081-15.882,15.784-15.998c4.148-0.076,8.154,1.58,11.224,4.609c3.173,3.13,4.992,7.448,4.992,11.848v21.541v6V46.999z
		                                M52.499,43.999h-4v-2h4V43.999z"
      />
      <polygon
        points="28.206,36.292 26.414,34.499 28.206,32.706 26.792,31.292 24.999,33.085 23.206,31.292 21.792,32.706 
		                                23.585,34.499 21.792,36.292 23.206,37.706 24.999,35.913 26.792,37.706 	"
      />
      <polygon
        points="37.792,37.706 39.206,36.292 37.414,34.499 39.206,32.706 37.792,31.292 35.999,33.085 34.206,31.292 
		                                32.792,32.706 34.585,34.499 32.792,36.292 34.206,37.706 35.999,35.913 	"
      />
      <polygon
        points="22.792,39.292 20.999,41.085 19.206,39.292 17.792,40.706 19.585,42.499 17.792,44.292 19.206,45.706 
		                                20.999,43.913 22.792,45.706 24.206,44.292 22.414,42.499 24.206,40.706 	"
      />
      <polygon
        points="32.792,39.292 30.999,41.085 29.206,39.292 27.792,40.706 29.585,42.499 27.792,44.292 29.206,45.706 
		                                30.999,43.913 32.792,45.706 34.206,44.292 32.414,42.499 34.206,40.706 	"
      />
      <polygon
        points="42.792,39.292 40.999,41.085 39.206,39.292 37.792,40.706 39.585,42.499 37.792,44.292 39.206,45.706 
		                                40.999,43.913 42.792,45.706 44.206,44.292 42.414,42.499 44.206,40.706 	"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>