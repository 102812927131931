<template>
  <svg
    version="1.1"
    id="Capa_1"
    fill="#ee27bc"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 490.858 490.858"
    style="enable-background: new 0 0 490.858 490.858"
    xml:space="preserve"
  >
    <g>
      <path
        d="M132.471,175.624c2.347,0,4.25-1.902,4.25-4.25c0-49,109.347-98.713,110.452-99.209c2.141-0.963,3.097-3.478,2.134-5.619
		                                c-0.96-2.141-3.476-3.096-5.617-2.135c-4.715,2.118-115.468,52.494-115.468,106.963
		                                C128.221,173.721,130.124,175.624,132.471,175.624z"
      />
      <path
        d="M427.138,166.452h-7.393c-2.244-34.126-31.379-72.098-86.688-112.948C290.7,22.217,247.758,0.662,247.329,0.447
		                                c-1.195-0.596-2.602-0.596-3.797,0c-0.429,0.215-43.372,21.771-85.73,53.056c-55.308,40.851-84.444,78.823-86.688,112.949H63.72
		                                c-2.347,0-4.25,1.902-4.25,4.25v315.906c0,2.348,1.903,4.25,4.25,4.25c2.347,0,4.25-1.902,4.25-4.25v-15.576h25.332v15.576
		                                c0,2.348,1.903,4.25,4.25,4.25s4.25-1.902,4.25-4.25V175.624h2.718c2.347,0,4.25-1.902,4.25-4.25
		                                c0-57.963,120.385-118.613,136.661-126.543c16.275,7.93,136.659,68.58,136.659,126.543c0,2.348,1.902,4.25,4.25,4.25h2.717v310.984
		                                c0,2.348,1.902,4.25,4.25,4.25c2.348,0,4.25-1.902,4.25-4.25v-15.576h25.332v15.576c0,2.348,1.902,4.25,4.25,4.25
		                                c2.348,0,4.25-1.902,4.25-4.25V170.702C431.388,168.354,429.486,166.452,427.138,166.452z M397.556,201.698h25.332v242.834h-25.332
		                                V201.698z M93.302,444.532H67.97V201.698h25.332V444.532z M67.97,462.532v-9.5h25.332v9.5H67.97z M247.232,36.264
		                                c-1.143-0.533-2.461-0.533-3.604,0c-5.73,2.683-137.611,65.164-143.178,130.859h-2.899c-2.347,0-4.25,1.902-4.25,4.25v21.824H67.97
		                                v-18.246h7.253c2.347,0,4.25-1.902,4.25-4.25c0-73.387,147.407-152.072,165.957-161.678
		                                c18.551,9.602,165.956,88.265,165.956,161.678c0,2.348,1.902,4.25,4.25,4.25h7.252v18.246h-25.332v-21.824
		                                c0-2.348-1.902-4.25-4.25-4.25h-2.898C384.842,101.428,252.963,38.947,247.232,36.264z M397.556,462.532v-9.5h25.332v9.5H397.556z"
      />
      <path
        d="M202.398,455.659h-28.876c-2.347,0-4.25,1.902-4.25,4.25c0,2.348,1.903,4.25,4.25,4.25h28.876
		                                c2.347,0,4.25-1.902,4.25-4.25C206.648,457.561,204.745,455.659,202.398,455.659z"
      />
      <path
        d="M314.862,345.038c3.346-9.084,13.531-36.279,13.531-36.279c0.547-1.467,0.243-3.116-0.791-4.292
		                                c-1.033-1.176-2.633-1.689-4.154-1.333l-24.145,5.617l-1.376-2.944c-0.851-1.817-2.859-2.795-4.808-2.341l-66.739,15.433
		                                c-1.25,0.289-2.302,1.127-2.864,2.28c-0.562,1.153-0.572,2.498-0.029,3.66l10.085,21.576l-11.178,2.566
		                                c0.097-5.789,0.086-15.92-0.781-26.504c-1.228-15.002-3.743-25.642-7.618-32.129c4.502-1.077,8.86-2.846,12.922-5.332
		                                c10.146-6.209,17.268-15.997,20.052-27.563c3.53-14.672-0.744-30.295-11.137-41.188l-2.461-20.588
		                                c-0.579-4.834-4.683-8.479-9.547-8.479c-0.381,0-0.767,0.022-1.148,0.068l-53.331,6.379c-5.268,0.63-9.04,5.429-8.409,10.695
		                                l2.761,23.097c-1.428,2.921-2.556,5.993-3.32,9.17c-2.784,11.564-0.898,23.521,5.311,33.668c1.389,2.27,2.967,4.38,4.694,6.332
		                                c-9.878,6.466-31.837,25.247-37.247,66.902c-2.584,19.898-3.879,48.752-0.614,65.078l0.163,0.817
		                                c1.733,8.713,4.068,20.39,16.009,26.631c-4.562,1.736-8.969,4.168-12.499,7.587c-5.139,4.979-7.745,11.281-7.745,18.732v11.44
		                                c0,1.129,0.449,2.211,1.248,3.008c0.797,0.796,1.876,1.242,3.002,1.242c0.002,0,0.005,0,0.008,0l122.372-0.222
		                                c26.942,0,43.096-12.719,44.318-34.896c0.952-17.264-1.818-38.057-31.943-45.676c-1.293-0.327-2.62-0.643-3.972-0.963
		                                c-8.753-2.077-17.748-4.23-24.746-11.262l34.529-7.997c8.372-1.923,13.622-10.3,11.703-18.674l-0.962-4.192l27.834-6.475
		                                C314.179,347.031,314.485,346.061,314.862,345.038z M170.356,202.086l53.33-6.379c0.047-0.005,0.094-0.008,0.14-0.008
		                                c0.564,0,1.04,0.425,1.107,0.988l2.143,17.927l-55.314,8.679l-0.412-3.447l17.666-2.392c2.326-0.316,3.956-2.456,3.641-4.782
		                                c-0.314-2.326-2.456-3.948-4.782-3.642l-17.534,2.373l-0.965-8.071C169.303,202.719,169.742,202.16,170.356,202.086z
		                                M168.641,238.598c0.544-2.263,1.309-4.462,2.269-6.567l59.343-9.311c8.008,8.784,11.247,21.137,8.454,32.744
		                                c-4.65,19.318-24.146,31.254-43.467,26.6c-9.357-2.252-17.277-8.014-22.301-16.224C167.915,257.63,166.388,247.956,168.641,238.598
		                                z M261.522,404.562c1.31,0.311,2.597,0.616,3.85,0.933c22.574,5.71,26.536,18.907,25.541,36.967
		                                c-1.225,22.203-20.161,26.864-35.84,26.864l-118.122,0.214v-5.381h23.311c2.347,0,4.25-1.902,4.25-4.25
		                                c0-2.348-1.903-4.25-4.25-4.25h-22.13c0.851-2.2,2.161-4.166,3.966-5.917c7.363-7.145,20.64-8.915,27.711-9.341
		                                c0.119,0.001,0.23,0.007,0.35,0.007c1.514,0,3.099-0.044,4.746-0.133c37.783-2.058,44.941-0.89,48.381-0.328
		                                c1.053,0.172,2.048,0.334,3.373,0.334c2.347,0,4.25-1.902,4.25-4.25c0-2.348-1.903-4.25-4.25-4.25c-0.61,0-1.055-0.068-2.004-0.224
		                                c-4.016-0.655-11.489-1.871-50.034,0.221c-0.635-0.017-2.617-0.039-5.427,0.133c-23.4-0.219-25.725-11.871-28.171-24.163
		                                l-0.165-0.825c-2.939-14.698-1.869-42.476,0.708-62.317c5.42-41.735,28.435-58.036,35.278-62.015
		                                c4.815,3.635,10.365,6.283,16.407,7.737c3.486,0.84,7.008,1.254,10.505,1.254c0.04,0,0.079-0.004,0.119-0.004
		                                c5.138,2.645,8.381,17.58,9.587,35.34l-4.753-20.712c-1.635-7.125-7.876-12.102-15.179-12.102c-1.171,0-2.347,0.134-3.497,0.397
		                                l-7.364,1.691c-4.057,0.931-7.509,3.386-9.719,6.913c-2.21,3.527-2.915,7.704-1.983,11.76l15.215,66.31
		                                c1.634,7.124,7.875,12.099,15.175,12.099h0.001c1.172,0,2.35-0.134,3.507-0.399l24.487-5.671
		                                C238.47,399.09,251.182,402.108,261.522,404.562z M271.357,368.75l-68.402,15.843c-0.527,0.121-1.063,0.182-1.596,0.182
		                                c-3.314,0-6.148-2.261-6.891-5.499l-15.215-66.311c-0.423-1.844-0.104-3.742,0.901-5.345c0.33-0.526,0.726-0.994,1.167-1.408
		                                l7.023,34.388c0.411,2.013,2.182,3.4,4.16,3.4c0.282,0,0.568-0.028,0.855-0.087c2.3-0.47,3.783-2.715,3.314-5.015l-7.257-35.532
		                                l2.52-0.579c0.526-0.121,1.063-0.183,1.595-0.183c3.316,0,6.15,2.263,6.894,5.502l10.959,47.762
		                                c0.253,1.099,0.931,2.053,1.886,2.651c0.955,0.598,2.109,0.794,3.207,0.541l50.03-11.483c0.526-0.121,1.063-0.182,1.595-0.182
		                                c3.315,0,6.15,2.262,6.895,5.5l1.69,7.363C277.557,364.066,275.171,367.874,271.357,368.75z M281.235,346.084
		                                c-2.789-4.396-7.664-7.189-13.134-7.189c-1.17,0-2.347,0.133-3.496,0.396l-22.559,5.178l-8.635-18.473l58.271-13.475l1.365,2.921
		                                c0.002,0.004,0.004,0.007,0.005,0.011l11.755,25.147L281.235,346.084z M310.439,332.572l-7.456-15.95l14.679-3.415L310.439,332.572z"
      />
      <path
        d="M375.208,185.866c-2.348,0-4.25,1.902-4.25,4.25v132c0,2.348,1.902,4.25,4.25,4.25c2.348,0,4.25-1.902,4.25-4.25v-132
		                                C379.458,187.768,377.555,185.866,375.208,185.866z"
      />
      <path
        d="M375.208,343.358c-2.348,0-4.25,1.902-4.25,4.25v35.524c0,2.348,1.902,4.25,4.25,4.25c2.348,0,4.25-1.902,4.25-4.25
		                                v-35.524C379.458,345.26,377.555,343.358,375.208,343.358z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>